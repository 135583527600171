import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import UpdateEmployer from "./UpdateEmployer";
import UpdateEmployee from "./UpdateEmployee";
import { roles } from "variables/roles";
import { apiv2 } from "variables/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function UpdateModal({ users, classicModal, setClassicModal, setLoading }) {
  const [userToUpdate, setUserToUpdate] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (users.filter((user) => user._id === classicModal.userID).length > 0) {
      const targetUser = users.filter(
        (user) => user._id === classicModal.userID
      );
      apiv2
      .get(
        `/${targetUser[0].role === "employee" ? "user" : "company"}?id=${
          targetUser[0]._id
        }`
      )
      .then((res) => {
        setUserToUpdate(res.data.data.user);
      })
      .catch((err) => console.error(err));
    } else {
      apiv2
        .get(
          `/${classicModal.user.role === "employee" ? "user" : "company"}?id=${
            classicModal.user._id
          }`
        )
        .then((res) => {
          setUserToUpdate(res.data.data.user);
        })
        .catch((err) => console.error(err));
    }
  }, [users, classicModal.userID]);

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={classicModal.isVisible}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: "650px",
          width: "100%",
        },
      }}
      onClose={() => setClassicModal({ ...classicModal, isVisible: false })}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setClassicModal({ ...classicModal, isVisible: false })}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Save User</h4>
      </DialogTitle>
      {userToUpdate ? (
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {userToUpdate.role === roles.employer ||
          userToUpdate.role === roles.superuser ? (
            <UpdateEmployer
              classicModal={classicModal}
              setClassicModal={setClassicModal}
              user={userToUpdate}
              setLoading={setLoading}
            />
          ) : (
            <UpdateEmployee
              classicModal={classicModal}
              setClassicModal={setClassicModal}
              user={userToUpdate}
              setLoading={setLoading}
            />
          )}
        </DialogContent>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{
            padding: "50px",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
}

UpdateModal.propTypes = {
  users: PropTypes.any,
  classicModal: PropTypes.any,
  setClassicModal: PropTypes.func,
  setLoading: PropTypes.func,
};

export default UpdateModal;
