/*eslint-disable*/
import React from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || "",
        onChange: (e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Search records...`,
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
  cName,
  columns,
  data,
  pageIndex,
  setPageIndex,
  numberOfRows,
  setNumberOfRows,
  initSortBy,
  setSortBy,
  initFilters,
  setFilters,
  tableInitialState,
  showPagination = true,
  lastHeader = false,
  onFetchData,
  totalPages,
}) {
  const [refresh, setRefresh] = React.useState(new String());
  const optionsChanged = React.useRef(false);
  const delayedFilter = React.useRef(-1);
  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  let today = new Date();
  today.setDate(today.getDate() - 2);
  today.setUTCHours(23, 59, 99, 999);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { sortBy, filters },
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageSize: numberOfRows,
        pageIndex: pageIndex,
        sortBy: initSortBy,
        filters: initFilters,
        ...tableInitialState,
      },
      manualPagination: true,
      manualSortBy: true,
      manualFiltering: true,
      pageCount: totalPages ?? 1,
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 100);

  React.useEffect(() => {
    if (optionsChanged.current) {
      optionsChanged.current = false;
      let sort = undefined;
      if (sortBy.length > 0) {
        sort = sortBy[0];
      }
      let filter = undefined;
      if (filters.length > 0) {
        filter = filters[0];
      }
      onFetchDataDebounced(pageIndex, numberOfRows, sortBy, filters);
    }
  }, [refresh]);

  React.useEffect(() => {
    if (initSortBy !== sortBy) {
      optionsChanged.current = true;
      setRefresh(new String());
      console.log("SORT BY", sortBy); // TODO table triggers working now => changes to API
    }
    setSortBy(sortBy);
  }, [sortBy]);

  React.useEffect(() => {
    if (initFilters !== filters) {
      clearTimeout(delayedFilter.current);
      delayedFilter.current = setTimeout(() => {
        optionsChanged.current = true;
        setRefresh(new String());
        console.log("FILTER", filters);
      }, 750);
    }
    setFilters(filters);
  }, [filters]);

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () {});

  let numberOfRowsData = [5, 10, 20, 25, 50, 400];

  return (
    <>
      <div className="ReactTable -striped -highlight">
        {showPagination === true && (
          <div className="pagination-top">
            <div className="-pagination">
              <div className="-previous">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    const temp = Math.max(0, pageIndex - 1);
                    setPageIndex(temp);
                    optionsChanged.current = true;
                    setRefresh(new String());
                  }}
                  disabled={!canPreviousPage}
                  className="-btn"
                >
                  Previous
                </button>
              </div>
              <div className="-center">
                <GridContainer className={classes.gridContainer}>
                  <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        " " +
                        classes.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={pageIndex}
                        onChange={(event) => {
                          gotoPage(event.target.value);
                          setPageIndex(event.target.value);
                          optionsChanged.current = true;
                          setRefresh(new String());
                        }}
                        inputProps={{
                          name: "pageSelect",
                          id: "page-select",
                        }}
                      >
                        {pageSelectData.map((prop, key) => {
                          return (
                            <MenuItem
                              key={key}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={key}
                            >
                              Page {key + 1}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        " " +
                        classes.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={numberOfRows}
                        onChange={(event) => {
                          setPageSize(event.target.value);
                          setNumberOfRows(event.target.value);
                          setPageIndex(0);
                          optionsChanged.current = true;
                          setRefresh(new String());
                        }}
                        inputProps={{
                          name: "numberOfRows",
                          id: "number-of-rows",
                        }}
                      >
                        {numberOfRowsData.map((prop) => {
                          return (
                            <MenuItem
                              key={prop}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={prop}
                            >
                              {prop} rows
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </div>
              <div className="-next">
                <button
                  type="button"
                  onClick={() => {
                    nextPage();
                    const temp = pageIndex + 1;
                    setPageIndex(temp);
                    optionsChanged.current = true;
                    setRefresh(new String());
                  }}
                  disabled={!canNextPage}
                  className="-btn"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        <table
          {...getTableProps()}
          className={`rt-table ${cName ? cName : ""}`}
        >
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="rt-resizable-header-content"
                    >
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length - 1 === key && !lastHeader
                        ? null
                        : column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 },
                    {
                      " highlighted":
                        row.original.showAsTeaser &&
                        row.original.status === "Vacant" &&
                        !row.original.expired,
                    }
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`rt-td ${
                          cell.column.Header.toLowerCase() === "role"
                            ? "u-text-capitalize"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-bottom"></div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
