import { bookingConstants } from "../actions/types";

const initialState = {
  loading: false,
  data: [],
  jobPositions: [],
  totalPages: 0,
  error: "",
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case bookingConstants.GET_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case bookingConstants.GET_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.bookings,
        totalPages: action.totalPages,
        error: "",
      };
    case bookingConstants.GET_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case bookingConstants.GET_TIME_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case bookingConstants.GET_TIME_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        jobPositions: action.jobPositions,
        totalPages: action.totalPages,
        error: "",
      };
    case bookingConstants.GET_TIME_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default bookingReducer;
