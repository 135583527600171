import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { getOrders, deleteOrder } from "../../actions/orderAction";
import { convertToDateFromMS } from "../../variables/time";

import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import UserTable from "components/ReactTable/ReactTable";
// import UpdateModal from "./UpdateModal";
import moment from "moment";
import { ReportOutlined } from "@material-ui/icons";

const useStyles = makeStyles({ ...tableStyles, ...alertStyles });

export default function ExtendedTables() {
  const dispatch = useDispatch();
  const ordersData = useSelector((state) => state.orders);
  const totalPages = useSelector((state) => state.orders.totalPages);
  const [alert, setAlert] = React.useState(null);
  const [numberOfRows, setNumberOfRows] = React.useState(20);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [sortBy, setSortBy] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const history = useHistory();
  const [classicModal, setClassicModal] = React.useState(false);
  const [targetUserID, setTargetUserID] = React.useState(null);
  const [inputValue, setInputValue] = React.useState(null);

  const classes = useStyles();

  const onFetchData = async (page, size, sortBy, filters) => {
    dispatch(getOrders(page, size, sortBy, filters)).then(() => {});
  };

  useEffect(() => {
    // if (ordersData && ordersData.data && ordersData.data.length) return;
    dispatch(getOrders());
  }, []);

  const successDelete = async (orderID) => {
    dispatch(deleteOrder({ orderID }));
    dispatch(getOrders());
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Order has been deleted.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const warningWithConfirmMessage = (orderID) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(orderID)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to delete this order ?
      </SweetAlert>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Company",
        accessor: "companyName",
      },
      {
        Header: "Event Name",
        accessor: "eventName",
      },
      {
        Header: "Event Id",
        accessor: "eventID",
      },
      {
        Header: "Date",
        accessor: "orderDate",
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.orderDate, "DD-MM-YYYY").format("x");
          var b1 = moment(b?.values?.orderDate, "DD-MM-YYYY").format("x");
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: "Stripe Id",
        accessor: "stripeId",
      },
      {
        Header: "Transaction Id",
        accessor: "transactionId",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Active",
        accessor: "active",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ orderID, active }) => {
    let btnArr;
    if (active) {
      btnArr = [
        { type: "edit", color: "success", icon: Edit },
        { type: "delete", color: "danger", icon: Delete },
      ];
    } else {
      btnArr = [{ type: "edit", color: "success", icon: Edit }];
    }
    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          onClick={() => {
            if (prop.type === "edit") {
              history.push(`/admin/orders/${orderID}`);
            } else if (prop.type === "delete") {
              warningWithConfirmMessage(orderID);
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const orders = ordersData.data;
    const filteredOrders = orders.map((order, id) => {
      return {
        ...order,
        id: id + 1,
        orderDate: order.orderDate,
        active: order.active,
        actions: roundButtons({
          orderID: order._id,
          active: order.active === 'YES',
        }),
        companyName: order.companyName,
        eventName: order.eventName,
        eventID: order.eventID,
        stripeId: order.stripeId,
        transactionId: order.transactionId,
      };
    });
    return (
      <UserTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={filteredOrders}
        onFetchData={onFetchData}
        totalPages={totalPages}
      />
    );
  }, [pageIndex, numberOfRows, ordersData.data]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Orders</h4>
          </CardHeader>
          <CardBody>
            <div className="btn-wrapper">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  history.push("/admin/orders/create-order");
                }}
              >
                Add Order
              </Button>
            </div>
            {ordersData.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>{renderTable}</>
            )}
          </CardBody>
        </Card>
        {/* {classicModal.isVisible && (
          <UpdateModal
            users={ordersData.data}
            classicModal={classicModal}
            setClassicModal={setClassicModal}
          />
        )} */}
      </GridItem>
    </GridContainer>
  );
}
