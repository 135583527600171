import { orderConstants } from "../actions/types";

const initialState = { loading: false, data: [], error: "" };

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderConstants.GET_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case orderConstants.GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.orders,
        totalPages: action.totalPages,
        error: "",
      };
    case orderConstants.GET_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case orderConstants.DELETE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case orderConstants.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case orderConstants.DELETE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default settingReducer;
