import { eventConstants } from "./types";
import axios from "axios";
import { toast } from "react-toastify";
import api, { apiv2 } from "../variables/api";

// GET Request to fetch all Events
export const getEvents = (page = 0, size = 20, sortBy = [], filters = []) => {
  const request = () => {
    return { type: eventConstants.GET_EVENT_REQUEST };
  };
  const success = (events, totalPages) => {
    return { type: eventConstants.GET_EVENT_SUCCESS, events, totalPages };
  };
  const failure = (error) => {
    return { type: eventConstants.GET_EVENT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const tempSortBy = {
        eventID: sortBy.some((s) => s.id === "eventID")
          ? sortBy.find((s) => s.id === "eventID")?.desc
            ? -1
            : 1
          : 0,
        companyName: sortBy.some((s) => s.id === "companyName")
          ? sortBy.find((s) => s.id === "companyName")?.desc
            ? -1
            : 1
          : 0,
        eventName: sortBy.some((s) => s.id === "eventName")
          ? sortBy.find((s) => s.id === "eventName")?.desc
            ? -1
            : 1
          : 0,
        eventExpirationDate: sortBy.some((s) => s.id === "eventExpirationDate")
          ? sortBy.find((s) => s.id === "eventExpirationDate")?.desc
            ? -1
            : 1
          : 0,
        from: sortBy.some((s) => s.id === "eventStart")
          ? sortBy.find((s) => s.id === "eventStart")?.desc
            ? -1
            : 1
          : 0,
        to: sortBy.some((s) => s.id === "eventEnd")
          ? sortBy.find((s) => s.id === "eventEnd")?.desc
            ? -1
            : 1
          : 0,
      };
      const tempFilters = {
        eventID: filters.find((s) => s.id === "eventID")?.value ?? "",
        companyName: filters.find((s) => s.id === "companyName")?.value ?? "",
        eventName: filters.find((s) => s.id === "eventName")?.value ?? "",
        eventExpirationDate:
          filters.find((s) => s.id === "eventExpirationDate")?.value ?? "",
        from: filters.find((s) => s.id === "eventStart")?.value ?? "",
        to: filters.find((s) => s.id === "eventEnd")?.value ?? "",
      };
      tempSortBy.outstandingAmount = sortBy.some(
        (s) => s.id === "outstandingAmount"
      )
        ? sortBy.find((s) => s.id === "outstandingAmount")?.desc
          ? -1
          : 1
        : 0;
      tempFilters.outstandingAmount =
        filters.find((s) => s.id === "outstandingAmount")?.value ?? "";
      const data = {
        page: page,
        size: size,
        sortBy: tempSortBy,
        filters: tempFilters,
      };
      const events = await apiv2.post(`/events`, {
        ...data,
      });
      dispatch(success(events.data.data.events, events.data.data.totalPages));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong!"));
        toast.error("Something went wrong!");
      }
    }
  };
};

// POST Request to Create Event
export const createEvent = ({ payload, callback }) => {
  const request = () => {
    return { type: eventConstants.CREATE_EVENT_REQUEST };
  };
  const success = (event) => {
    return {
      type: eventConstants.CREATE_EVENT_SUCCESS,
      event,
    };
  };
  const failure = (error) => {
    return { type: eventConstants.CREATE_EVENT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const event = await api.post(`/events/`, payload);
      dispatch(success(event.data.data.event));
      toast.success("Created event Successfully!");
      if (callback) {
        callback();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const exportevent = ({ id }) => {};

// PATCH Request to Update Event
export const udpateEvent = ({ id, payload }) => {
  const request = () => {
    return { type: eventConstants.UPDATE_EVENT_REQUEST };
  };
  const success = () => {
    return {
      type: eventConstants.UPDATE_EVENT_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: eventConstants.UPDATE_EVENT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.patch(`/events/updateEvent/${id}`, payload);
      toast.success("Updated event successfully!");
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

// DELETE Request to delete Event
export const deleteEvent = ({ id, callback }) => {
  const request = () => {
    return { type: eventConstants.DELETE_EVENT_REQUEST };
  };
  const success = ({ events }) => {
    return {
      type: eventConstants.DELETE_EVENT_SUCCESS,
      events,
    };
  };
  const failure = (error) => {
    return { type: eventConstants.DELETE_EVENT_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      await api.delete(`/events/deleteEvent/${id}`);
      const newEvents = getState().events.data.filter(
        (event) => event._id !== id
      );
      dispatch(
        success({
          events: newEvents,
        })
      );
      if (callback) {
        callback();
      }
      toast.success("Deleted event successfully!");
    } catch (error) {
      if (callback) {
        callback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};
