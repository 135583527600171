import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

import EmailIcon from "@material-ui/icons/Email";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import JobPositionsTable from "components/ReactTable/LegacyReactTable";
import api from "../../variables/api";
import { handleError } from "../../variables/handleError";
import { convertToDateFromMS } from "../../variables/time";
import JobPositionModal from "./JobPositionModal";
import AddJobPositionModal from "./AddJobPositionModal";
import RejectedApplicationsModal from "./RejectedApplicationsModal";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { getTimeDifferent, htmlDecode } from "variables/utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { bonusOptions } from "variables/jobs.constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles({ ...styles, ...tableStyles, ...alertStyles });

const Job = ({ job, jobindex, setEventLoading, setEvent, setUpdate }) => {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [classicModal, setClassicModal] = useState({ isVisible: false });
  const [addJobModal, setAddJobModal] = useState({ isVisible: false });
  const [rejectedApplicationsModalData, setRejectedApplicationsModalData] = useState({ isVisible: false });
  const [jobPositionsList, setJobPositionsList] = useState([]);
  const [alert, setAlert] = useState(null);
  const [staffTypes, setStaffTypes] = useState([]);

  const { companyName, jobName, jobRequirements, jobBonus, jobDescription, _id, jobPositions } = job;
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        let response = await api.get("/global-settings");
        let resJobs = response.data.settings[0].staffTypes;
        setStaffTypes(response.data.settings[0].staffTypes);
        setLoading(false);
        const filteredJobPositions = resJobs.map((job) => ({
          label: job.name,
          value: job.name,
        }));
        setJobPositionsList(filteredJobPositions);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    })();
  }, []);
  const successDelete = async (jobID) => {
    let isMounted = true;
    setEventLoading(true);
    try {
      await api.delete(`/jobs/deleteJob/${jobID}`);
      const dataEvent = await api.get(`/events/getEvent/${id}`);
      if (isMounted) {
        setEvent(dataEvent.data.data.event);
      }
      setEventLoading(false);
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          Your job has been deleted.
        </SweetAlert>
      );
    } catch (e) {
      setEventLoading(false);
      handleError(e);
    }
    // Clean-up:
    return () => {
      isMounted = false;
    };
  };
  const successDelete1 = async (positionId) => {
    const deletedPosition = formik.values.jobPositions.find((position) => {
      return position._id === positionId;
    });
    api.patch(`/jobs/updateJobByAdmin/${job._id}`, {
      jobPositions: [],
      deletedPosition: deletedPosition,
    }).then(async res => {
      hideAlert();
      toast.success("Jobposition deleted Successfully!");
      await formik.setFieldValue("jobPositions", res.data.data.job.jobPositions);
    }).catch(e => {
      hideAlert()
      handleError(e);
    });
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const sendMail = async (positiondata) => {
    setLoading(true);
    try {
      await api.post(`/events/sendStaffMail`, {
        positiondata: positiondata,
        jobName: jobName,
        jobid: job._id,
      });
      setLoading(false);
      toast.success("Email sent to users successfuly!");
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const warningWithConfirmMessage = (jobID) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(jobID)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to delete this job ?
      </SweetAlert>
    );
  };

  const warningWithConfirmMessage1 = (positionId) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete1(positionId)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to delete this job position ?
      </SweetAlert>
    );
  };
  const formik = useFormik({
    initialValues: {
      jobName,
      jobRequirements: htmlDecode(jobRequirements),
      jobBonus: jobBonus ? jobBonus.replace("%", "") : "",
      jobDescription: htmlDecode(jobDescription),
      jobPositions,
    },
    validationSchema: Yup.object({
      jobName: Yup.string().required("Job name is required"),
      jobBonus: Yup.number().typeError("Field must be a number").required("Job Bonus is required"),
    }),
    onSubmit: async (values) => {
      const staffTypeId = staffTypes.find((s) => s.name === values.jobName)._id;

      setLoading(true);
      api.patch(`/jobs/updateJobByAdmin/${job._id}`, {
        jobName: values.jobName,
        jobRequirements: values.jobRequirements,
        jobDescription: values.jobDescription,
        jobBonus: values.jobBonus,
        staffType: staffTypeId,
      }).then(res => {
        job = res.data.data.job;
        setLoading(false);
        toast.success("Updated Job Successfully!");
      }).catch(e => {
        setLoading(false);
        handleError(e);
      });
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Job Place",
        accessor: "jobPlace",
      },
      {
        Header: "Job Date",
        accessor: "jobDate",
      },
      {
        Header: "Job Start",
        accessor: "jobStart",
      },
      {
        Header: "Job End",
        accessor: "jobEnd",
      },
      {
        Header: "Job Price",
        accessor: "jobPrice",
      },
      {
        Header: "Job Travel Cost",
        accessor: "jobTravelCost",
      },
      {
        Header: "Job Total Cost",
        accessor: "jobTotalCost",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "BookedOn",
        accessor: "bookedon",
      },
      {
        Header: "BookedUser",
        accessor: "bookeduser",
      },
      {
        Header: "Contract",
        accessor: "contractId",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ data }) => {
    let btnArr = [
      { type: "edit", color: "success", icon: Edit },
      { type: "delete", color: "danger", icon: Delete },
      { type: "email", color: "info", icon: EmailIcon },
    ];
    if (data?.rejectedApplicants?.length > 0) {
      btnArr.push({ type: "rejected", color: "info", icon: AnnouncementIcon });
    }
    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          style={{ margin: "0 0 0 1px" }}
          key={key}
          disabled={prop.type === "email" && (!data._id || data._id === "undefined")}
          onClick={() => {
            if (prop.type === "edit") {
              setClassicModal({
                isVisible: true,
                position: data,
                // eventExpirationDate ,
              });
            } else if (prop.type === "delete") {
              warningWithConfirmMessage1(data._id);
            } else if (prop.type === "email") {
              const positiondata = {
                _id: data._id,
                jobDate: data.jobDate,
                jobEnd: data.jobEnd,
                jobPlace: data.jobPlace,
                jobPlaceAddress: data.jobPlaceAddress,
                jobStart: data.jobStart,
                booked: data?.booked?.employee?._id ? true : false,
              };
              sendMail(positiondata);
            } else if (prop.type === "rejected" && data?.rejectedApplicants?.length > 0) {
              setRejectedApplicationsModalData({
                isVisible: true,
                position: data,
                // eventExpirationDate ,
              });
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };
  const renderpdfcontract = (url, employee) => {
    let cleanedName = employee
      .replace(/\s*\+\s*/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Contract-" + moment().format("DD.MM.YYYY") + "-" + cleanedName + ".pdf");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };
  const getContactUrl = async (positionid, employeeid, employeeName) => {
    await api
      .post("contracts/fetchcontracturl", {
        jobPositionId: positionid,
        employeeid: employeeid,
      })
      .then((res) => {
        // window.open(res.data.contracturl, '_blank');
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.contracturl[0].data).buffer])
        );
        renderpdfcontract(url, employeeName);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };
  const downloadcontract = async (jobID, positionid) => {
    await api.post(`/jobs/contractdownloaded/${jobID}`, { id: positionid });
  };

  const renderTable = (positions) => {
    const filteredPositions = positions.map((position, id) => {
      const jobStart = position.confirmJobStart
        ? moment(parseInt(position.confirmJobStart))
        : moment(parseInt(position.jobStart));
      const jobEnd = position.confirmJobEnd
        ? moment(parseInt(position.confirmJobEnd))
        : moment(parseInt(position.jobEnd));
      return {
        ...position,
        id: id + 1,
        jobDate: moment(convertToDateFromMS(jobStart)).format("DD-MM-YYYY"),
        expired: job.eventExpirationDate < moment().subtract(1, "days"),
        jobStart: jobStart.format("HH:mm"),
        jobEnd: jobEnd.format("HH:mm"),
        // jobPrice: position.confirmJobPrice
        //   ? position.confirmJobPrice
        //   : position.jobPrice,
        jobPrice:
          position?.employerData?.confirmJobPrice > 0
            ? Number(position?.employerData?.confirmJobPrice).toFixed(2)
            : Number(position?.employerData?.jobPrice ? position?.employerData?.jobPrice : 0).toFixed(2),
        jobTravelCost: Number(
          position?.employerData?.jobTravelCost ? position?.employerData?.jobTravelCost : 0
        ).toFixed(2),
        jobTotalCost: position?.employerData?.confirmJobTotalCost
          ? Number(position?.employerData?.confirmJobTotalCost).toFixed(2)
          : Number(position?.employerData?.jobTotalCost ? position?.employerData?.jobTotalCost : 0).toFixed(
              2
            ),
        status: position.booked && position.booked.employee ? "Booked" : "Vacant",
        bookedon:
          position.booked && position.booked.employee
            ? moment(convertToDateFromMS(position.booked.bookedAt)).format("DD.MM.YYYY")
            : "",
        bookeduser: position.booked && position.booked.employee ? position.booked.employee.name : "",
        contractId: position?.contractId ? (
          <div
            className="open_contract"
            onClick={() => {
              downloadcontract(job._id, position._id);
              getContactUrl(position._id, position.booked.employee._id, position.booked.employee.name);
              // window.open(position?.signedContract, "_blank")
            }}
          >
            {position?.contractId?.contract?.contractID}
          </div>
        ) : (
          ""
        ),
        actions: roundButtons({
          data: position,
        }),
      };
    });

    return (
      <JobPositionsTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        columns={columns}
        data={filteredPositions}
      />
    );
  };

  return (
    <div className="job-details-event-in">
      {alert}
      {rejectedApplicationsModalData.isVisible && (
        <RejectedApplicationsModal
          classicModal={rejectedApplicationsModalData}
          setClassicModal={setRejectedApplicationsModalData}
        />
      )}

      {classicModal.isVisible && (
        <JobPositionModal
          parentFormik={formik}
          classicModal={classicModal}
          setClassicModal={setClassicModal}
        />
      )}

      {addJobModal.isVisible && (
        <AddJobPositionModal
          parentFormik={formik}
          classicModal={addJobModal}
          setClassicModal={setAddJobModal}
          jobId={job._id}
        />
      )}

      <div className="title-wrapper">
        <h3>Job {jobindex}</h3>
        <div
          className="delete-box"
          onClick={() => {
            warningWithConfirmMessage(job._id);
          }}
        >
          <Delete />
        </div>
      </div>

      <div className="job-details-inner">
        {loading && (
          <div className="loader-in">
            <CircularProgress />
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <GridContainer>
            <GridItem xs={6} lg={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                  Job Name
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={formik.values.jobName}
                  onChange={(e) => {
                    formik.setFieldValue("jobName", e.target.value);
                  }}
                  inputProps={{
                    name: "jobName",
                    id: "jobName",
                    // selected: jobBonus,
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Job Type
                  </MenuItem>
                  {jobPositionsList.map((jobPosition, id) => (
                    <MenuItem
                      key={jobPosition + id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={jobPosition.value}
                    >
                      {jobPosition.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.jobName && formik.errors.jobName && (
                  <div className="error-message">{formik.errors.jobName}</div>
                )}
              </FormControl>
            </GridItem>
            <GridItem xs={6} lg={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                  Job Bonus (in % percentage)
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={formik.values.jobBonus}
                  onChange={(e) => {
                    formik.setFieldValue("jobBonus", e.target.value);
                  }}
                  inputProps={{
                    name: "jobBonus",
                    id: "job-bonus",
                    selected: jobBonus,
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Job Bonus
                  </MenuItem>
                  {bonusOptions.map((bonus, id) => (
                    <MenuItem
                      key={bonus + id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={bonus.value}
                    >
                      {bonus.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.jobBonus && formik.errors.jobBonus && (
                  <div className="error-message">{formik.errors.jobBonus}</div>
                )}
              </FormControl>
            </GridItem>
            <GridItem xs={6} lg={6} style={{ marginTop: "20px" }}>
              <label className="form-label text-left">Job Description</label>
              <ReactQuill
                theme="snow"
                modules={modules}
                value={formik.values.jobDescription}
                onChange={(e) => {
                  formik.setFieldValue("jobDescription", e);
                }}
                // onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={6} lg={6} style={{ marginTop: "20px" }}>
              <label className="form-label text-left">Operational clothing/outfit/skills</label>
              <ReactQuill
                theme="snow"
                modules={modules}
                value={formik.values.jobRequirements}
                onChange={(e) => {
                  formik.setFieldValue("jobRequirements", e);
                }}
                // onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} lg={12}>
              <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                <Button
                  type="button"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  Button
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                >
                  Update Job
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12} lg={12}>
              <div className="job-positions-wrapper">
                <div className="title-wrapper">
                  <h4>Job Positions</h4>
                </div>
                <div className="job-position-box">
                  {formik.values.jobPositions && formik.values.jobPositions.length ? (
                    renderTable(formik.values.jobPositions)
                  ) : (
                    <p>No Job Position Found!</p>
                  )}
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} lg={12}>
              <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                <Button
                  type="button"
                  Button
                  color={"success"}
                  onClick={(e) => setAddJobModal({ ...addJobModal, isVisible: true })}
                >
                  Add Job Position
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    </div>
  );
};

Job.propTypes = {
  job: PropTypes.any,
  jobindex: PropTypes.number,
  setEventLoading: PropTypes.any,
  setEvent: PropTypes.any,
  setUpdate: PropTypes.func.isRequired,
};

export default Job;
