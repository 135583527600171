import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Assignment from "@material-ui/icons/Assignment";
import Info from "@material-ui/icons/Info";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable";
import { disablePastDates, convertMsToHM } from "variables/utils";
import { getBookings } from "actions/bookingAction";
import { convertToDateFromMS } from "../../variables/time";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import moment from "moment";

const useStyles = makeStyles(styles);

const Bookings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [numberOfRows, setNumberOfRows] = React.useState(20);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [sortBy, setSortBy] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: "create",
  });
  const [bookingLoading, setBookingLoading] = React.useState(false);
  const bookingData = useSelector((state) => state.bookings);
  const totalPages = useSelector((state) => state.bookings.totalPages);
  const classes = useStyles();

  const onFetchData = async (page, size, sortBy, filters) => {
    dispatch(getBookings(page, size, sortBy, filters)).then(() => {});
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "jobDate",
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.jobDate, "DD-MM-YYYY").format("x");
          var b1 = moment(b?.values?.jobDate, "DD-MM-YYYY").format("x");
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: "Event ID",
        accessor: "eventID",
      },
      {
        Header: "Event Name",
        accessor: "eventName",
      },
      {
        Header: "Employee",
        accessor: "employeeName",
      },
      {
        Header: "Gender",
        accessor: "employeeGender",
      },
      {
        Header: "Hours Booked",
        accessor: "hourBooked",
      },
      {
        Header: "Report Confirmed",
        accessor: "reportConfirmed",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Contract Signed",
        accessor: "contractSignedDate",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ data }) => {
    let btnArr;
    btnArr = [{ type: "edit", color: "success", icon: Edit }];

    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          onClick={async () => {
            if (prop.type === "edit") {
              history.push(`/admin/events/${data._id}`);
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const bookings = bookingData?.data;
    const filteredBookings = [];
    bookings?.map((booking) => {
      filteredBookings.push({
        ...booking,
        reportConfirmed: "",
        actions: roundButtons({
          data: booking,
        }),
      });
    });
    return (
      <ReactTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={filteredBookings}
        onFetchData={onFetchData}
        totalPages={totalPages}
      />
    );
  }, [pageIndex, numberOfRows, bookingData]);

  useEffect(() => {
    if (bookingData && bookingData.data && bookingData.data.length) return;
    dispatch(getBookings());
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bookings</h4>
          </CardHeader>
          <CardBody>
            {bookingData.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {bookingLoading && (
                  <div className="loader absolute-loader">
                    <CircularProgress />
                  </div>
                )}
                {renderTable}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Bookings;
