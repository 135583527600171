import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
// import Visibility from "@material-ui/icons/Visibility";
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ContractTable from "components/ReactTable/LegacyReactTable";
import CreateContract from "./CreateContract";
import { getAllContracts, deleteContract } from "../../actions/contractAction";
import api from "../../variables/api";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import moment from "moment";
import { toast } from "react-toastify";

const useStyles = makeStyles({ ...styles, ...tableStyles, ...alertStyles });

const Contract = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = React.useState(null);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: "create",
  });
  const contractsData = useSelector((state) => state?.contracts);

  const classes = useStyles();

  const refreshList = () => {
    dispatch(getAllContracts());
  };
  const successDelete = async (ID) => {
    dispatch(
      deleteContract({
        id: ID,
        onSuccess: (res) => {
          refreshList();
        },
      })
    );

    dispatch(getAllContracts());

    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Contract has been deleted.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };
  const renderpdfcontract = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ActiveContract.pdf');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };
  const getContactUrl = async (contractpath) => {
    await api
      .post('contracts/getSelectedContract', {contractpath : contractpath})
      .then((res) => {
        // window.open(res.data.contracturl, '_blank');
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.contracturl[0].data).buffer]),
        );
        renderpdfcontract(url);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  const warningWithConfirmMessage = (ID) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(ID)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to delete this Contract ?
      </SweetAlert>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.createdAt, "DD-MM-YYYY").format("x");
          var b1 = moment(b?.values?.createdAt, "DD-MM-YYYY").format("x");
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ data }) => {
    let btnArr;
    btnArr = [
      { type: "edit", color: "success", icon: Edit },
      { type: "delete", color: "danger", icon: Delete },
      // { type: "view", color: "alert", icon: Visibility },
      { type: "Download", color: "alert", icon: GetAppIcon }
    ];

    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          onClick={async () => {
            if (prop.type === "edit") {
              setClassicModal({
                isVisible: true,
                data,
                type: "edit",
              });
            } else if (prop.type === "delete") {
              // deleteTestimonial;
              warningWithConfirmMessage(data._id);
            } else if (prop.type === "Download" && data?.contractfile && data?.contractfile !== '') {
              getContactUrl(data?.contractfile);
              // window.open(data?.contractfile, "_blank");
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const contracts = contractsData?.data?.Contracts;

    if (!contracts || !contracts?.length) {
      return <>No Data Found!</>;
    }

    const filteredContracts = contracts.map((contract, id) => ({
      ...contract,

      id: contract.contractID,
      status:
        contract.status && contract.status === true ? "Active" : "Deactive",
      createdAt: moment(contract.createdAt).format("DD-MM-YYYY"),
      actions: roundButtons({
        data: contract,
      }),
    }));
    return (
      <ContractTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        cName="contract-table"
        columns={columns}
        data={filteredContracts}
      />
    );
  }, [contractsData]);

  useEffect(() => {
    if (contractsData && contractsData?.data && contractsData?.data?.length)
      return;
    dispatch(getAllContracts());
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Contract</h4>
          </CardHeader>
          <CardBody>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) =>
                setClassicModal({
                  ...classicModal,
                  type: "create",
                  data: null,
                  isVisible: true,
                })
              }
            >
              Add Contract
            </Button>
            {contractsData?.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>{renderTable}</>
            )}
          </CardBody>
        </Card>
        {classicModal.isVisible && (
          <CreateContract
            classicModal={classicModal}
            setClassicModal={setClassicModal}
          />
        )}
      </GridItem>
    </GridContainer>
  );
};

export default Contract;
