import { orderConstants } from "./types";
import { toast } from "react-toastify";
import api, { apiv2 } from "../variables/api";

export const getOrders = (page = 0, size = 20, sortBy = [], filters = []) => {
  const request = () => {
    return { type: orderConstants.GET_ORDER_REQUEST };
  };
  const success = (orders, totalPages) => {
    return { type: orderConstants.GET_ORDER_SUCCESS, orders, totalPages };
  };
  const failure = (error) => {
    return { type: orderConstants.GET_ORDER_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const tempSortBy = {
        companyName: sortBy.some((s) => s.id === "companyName")
          ? sortBy.find((s) => s.id === "companyName")?.desc
            ? -1
            : 1
          : 0,
        eventName: sortBy.some((s) => s.id === "eventName")
          ? sortBy.find((s) => s.id === "eventName")?.desc
            ? -1
            : 1
          : 0,
        eventID: sortBy.some((s) => s.id === "eventID")
          ? sortBy.find((s) => s.id === "eventID")?.desc
            ? -1
            : 1
          : 0,
        orderDate: sortBy.some((s) => s.id === "orderDate")
          ? sortBy.find((s) => s.id === "orderDate")?.desc
            ? -1
            : 1
          : 0,
        stripeId: sortBy.some((s) => s.id === "stripeId")
          ? sortBy.find((s) => s.id === "stripeId")?.desc
            ? -1
            : 1
          : 0,
        transactionId: sortBy.some((s) => s.id === "transactionId")
          ? sortBy.find((s) => s.id === "transactionId")?.desc
            ? -1
            : 1
          : 0,
        status: sortBy.some((s) => s.id === "status")
          ? sortBy.find((s) => s.id === "status")?.desc
            ? -1
            : 1
          : 0,
        active: sortBy.some((s) => s.id === "active")
          ? sortBy.find((s) => s.id === "active")?.desc
            ? -1
            : 1
          : 0,
      };
      const tempFilters = {
        companyName: filters.find((s) => s.id === "companyName")?.value ?? "",
        eventName: filters.find((s) => s.id === "eventName")?.value ?? "",
        eventID: filters.find((s) => s.id === "eventID")?.value ?? "",
        orderDate: filters.find((s) => s.id === "orderDate")?.value ?? "",
        stripeId: filters.find((s) => s.id === "stripeId")?.value ?? "",
        transactionId:
          filters.find((s) => s.id === "transactionId")?.value ?? "",
        status: filters.find((s) => s.id === "status")?.value ?? "",
        active: filters.find((s) => s.id === "active")?.value ?? "",
      };
      const data = {
        page: page,
        size: size,
        sortBy: tempSortBy,
        filters: tempFilters,
      };
      const orders = await apiv2.post(`/orders`, {
        ...data,
      });
      dispatch(success(orders.data.data.orders, orders.data.data.totalPages));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const deleteOrder = ({ orderID }) => {
  const request = () => {
    return { type: orderConstants.DELETE_ORDER_REQUEST };
  };
  const success = () => {
    return { type: orderConstants.DELETE_ORDER_SUCCESS };
  };
  const failure = (error) => {
    return { type: orderConstants.DELETE_ORDER_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.delete(`/orders/deleteOrder/${orderID}`);
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};
