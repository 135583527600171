import { getUsers, updateUser, getUnapprovedUsers } from "actions/userAction";
import { updateEmployer } from "actions/employerAction";
import { updateEmployee } from "actions/employeeAction";

export const updateEmployerUser = ({ userID, employerID, payload, imagePathsToDelete, callback }) => {
  let formData = new FormData();
  const {
    country,
    city,
    postalCode,
    addressLineOne,
    addressLineTwo,
    contactPerson,
    paymentMethods,
  } = payload;

  Object.entries(payload).map((value) => {
    formData.append(value[0], value[1]);
  });

  return (dispatch) => {
    dispatch(
      updateUser({
        userID,
        payload: formData,
      })
    ).then((res) => {
      dispatch(
        updateEmployer({
          employerID,
          payload: {
            country,
            city,
            postalCode,
            addressLineOne,
            addressLineTwo,
            contactPerson,
            paymentMethods,
            imagePathsToDelete
          },
        })
      ).then((res) => {
        dispatch(getUsers(0, 20, 'companies')).then((res) => {
          callback();
        });
      });
    });
  };
};

export const updateEmployeeUser = ({
  userID,
  employeeID,
  payload,
  imagePathsToDelete,
  formData,
  callback
}) => {
  const { firstName, lastName, email, phone, active, role } = payload;
  if(imagePathsToDelete !==undefined){
    imagePathsToDelete.forEach((url, index) => {
      formData.append(`imagePathsToDelete[${index}]`, url);
    });
  }
  return (dispatch) => {
    dispatch(
      updateUser({
        userID,
        payload: { firstName, lastName, email, phone, active, role },
      })
    ).then((res) => {
      dispatch(updateEmployee({ employeeID, payload: formData })).then((res) => {
        dispatch(getUsers(0, 20, 'users')).then((res) => {
          dispatch(getUnapprovedUsers()).then((res) => { callback() })
        });
      });
    });


  };
};
